
import logo from './images/logo.png';

import {
    Link
} from "react-router-dom";

import Container from 'react-bootstrap/Container';
import { Navbar } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import { Link as Links } from 'react-scroll';
import NavDropdown from 'react-bootstrap/NavDropdown';

import ContattiBtnNavbar from './ContattiBtnNavbar';
{/*
import NewsBtnNavbar from './NewsBtnNavbar';
*/}
export const Navbar1 = () => {

    return (
        <>
            <Navbar bg="light" expand="lg" fixed='top'>
                <Container>
                    {/*
                    <NewsBtnNavbar />
                    */}
                    <ContattiBtnNavbar />
                    <img src={logo} alt="Compravendita di farmacie - Farmacontatto" width="60" height="60" style={{ marginRight: 15 }} className="d-inline-block align-text-top"></img>
                    <Navbar.Brand href="/" title="Farmacontatto"> Farmacontatto</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="navbar-brand" href="#">

                            <Nav.Link as={Link} to="/">Home</Nav.Link>
                        
                            <Nav.Link as={Link} to="/farmacie-in-vendita">Farmacie in vendita</Nav.Link>
                            <Nav.Link as={Link} to="/chi-siamo">Chi siamo</Nav.Link>
                            {/*
                            <Nav.Link as={Link} to="/servizi">Servizi</Nav.Link>
                            <Nav.Link as={Link} to="/InserzioniFarmacie/Tutte">Inserzioni farmacie</Nav.Link>
                            */}
                            <Nav.Link as={Link} to="/consigli">Consigli</Nav.Link>

                            <NavDropdown>
                                <NavDropdown.Item as={Link} to="/compravendita-farmacie">Compravendita farmacie</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/comprare-una-farmacia">Comprare una Farmacia</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/vendere-la-farmacia">Vendere una Farmacia</NavDropdown.Item>
                            </NavDropdown>

                            <NavDropdown title="Privacy & Cookie" id="basic-nav-dropdown">
                                <NavDropdown.Item as={Link} to="/condizioni">Condizioni d’uso e Termini del servizio</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/privacy">Trattamento dei dati personali (Privacy)</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/cookies">Informazioni Generali sull’Uso dei Cookie</NavDropdown.Item>
                            </NavDropdown>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default Navbar1;