import 'leaflet/dist/leaflet.css';
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Navbar1 } from './navbar1';
import { SideBar } from './SideBar';
import { MapContainer, TileLayer, Circle } from "react-leaflet";
import { NonDisponibile } from './NonDisponibile';

import Accordion from 'react-bootstrap/Accordion';
import envelope_circle_check_solid from './icone/envelope-circle-check-solid.svg';
import ContattiBtnModal from './ContattiBtnModal';

export const FarmaciaInVenditaCorpo = () => {
    
    const colorOptions = { color: '#6082B6' }
    const { url_pagina } = useParams();
    
  let api_url = "https://org.farmacontatto.it/PhsCrm/inserzioni/esegui/get_inserzione_web.php?p='" + url_pagina + "'"
  
    const [DatiJson, setFarma] = useState([])
    let [isLoaded, setIsLoaded] = useState(false);
    let [err, setErr] = useState(null);
    let [seo_description, setSeo] = useState(null);
    let [seo_titolo, setTitolo] = useState(null);

    useEffect(() => {
        const getFarma = () => {
            fetch(api_url)
                .then(response => {
                    if (response.status >= 400) {
                        throw new Error("Si è verificato un errore")
                    }
                    return response.json()
                })
                .then(DatiJson => {
                    setFarma(DatiJson)
                    setIsLoaded(true)
                    setSeo(DatiJson.map(farma => farma.testo_seo))
                    setTitolo(DatiJson.map(farma => farma.titolo_inserzione))
          
                },
                    err => {
                        setErr(err)
                        setIsLoaded(true)
                    })
        };
        getFarma()
    }, [])

    if (err) {
        return <div> {err.message} </div>
    } else if (!isLoaded) {
        return <div> Loading... </div>
    } else {
        return (
            <>
        <HelmetProvider>
          <Helmet>
            <title>{'Farmacontatto -  ' + seo_titolo}</title>
            <meta name="description" content={seo_description} />
            <link rel="canonical" href={"https://farmacontatto.it/farmacia-in-vendita/"  + url_pagina}/>
          </Helmet>
        </HelmetProvider>
              <Navbar1 />
              <div className='my-div mt-5'>
                <div className="row g-2 mt-5">
                  <div className="row g-2 mt-4">
                    <div className="col-md-9">
                      <article className="blog-post p-4"
                        style={{ backgroundImage: "linear-gradient(180deg, #F7F9FC, #FFFFFF)" }}>
                        <div className="row flex-md-row pt-3">

                          {DatiJson.length > 0 ?

                            <div className="col-md-12">      
                              {DatiJson.map(farma => (
                                <div className="row">
                                  <h1 className="blog-post-meta">
                                    {farma.titolo_inserzione}
                                  </h1>
                                  <div className="col-md-12">
                                    <div className="card flex-md-row mb-4 box-shadow h-md-250">
                                      <img className='rounded-circle m-3' src={"../../assets/img/Farmacie-in-vendita-" + farma.immagine + '.jpg'} alt={farma.title_immagine} title={farma.title_immagine} width="100" height="100" loading="lazy" />
                                      <div className="card-body d-flex flex-column align-items-start">
        
                                        <div className="container-fluid">
                                          <div className="row">
                                            <div className="col-4">
                                              <div className={"my-trattativa-" +  farma.colore_stato_trattative}>{farma.stato_trattative}</div>
                                            </div>
                                            <div className="col-8">
                                              <div className="text-end text-muted">
                                                Rif: {farma.rif_farm}
                                              </div>
                                            </div>
                                          </div>
                                          <h2 className="my-text-dark mt-2" href="#">{farma.titolo_inserzione}</h2>
                                          <p className="my-card-text text-start mx-4 ">{farma.testo_inserzione}</p>
        
                                          <div className="col-12 mt-4 mb-4">
                                            <Accordion defaultActiveKey="0">
                                              <Accordion.Item eventKey="0">
                                                <Accordion.Header>Fatturato: {farma.fatturato}</Accordion.Header>
                                                <Accordion.Body>
                                                  {farma.testo_fatturato}
                                                </Accordion.Body>
                                              </Accordion.Item>
                                              <Accordion.Item eventKey="1">
                                                <Accordion.Header>Finanziabilità: {farma.finanziabilita}</Accordion.Header>
                                                <Accordion.Body>
                                                  {farma.testo_finanziabilita}
                                                </Accordion.Body>
                                              </Accordion.Item>
                                              <Accordion.Item eventKey="2">
                                                <Accordion.Header>Altre caratteristiche: {farma.altre_caratteristiche}</Accordion.Header>
                                                <Accordion.Body>
                                                  {farma.testo_altre_caratteristiche}
                                                </Accordion.Body>
                                              </Accordion.Item>
                                            </Accordion>
                                          </div>
        
                                          <div className="row flex-md-row pt-3">
                                            <div className="col-6">
        
                                              <MapContainer center={{ lat: farma.lat, lng: farma.lng }}
                                                zoom={farma.zoom} scrollWheelZoom={false}>
                                                <TileLayer
                                                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                />
                                                <Circle
                                                  center={{ lat: farma.lat, lng: farma.lng }}
                                                  pathOptions={colorOptions}
                                                  radius={farma.raggio}
                                                  stroke={true}
                                                  fillOpacity={0.1}
                                                />
                                              </MapContainer>
                                            </div>
                                            <div className="col-6 mt-3">
                                            <img src={envelope_circle_check_solid} alt="Generic placeholder image" width="50" height="50" />
         
                                              <h2>Vuoi maggiori informazioni?</h2>
                                              <p>La trattativa per la farmacia in vendita è riservata. Contattaci per maggiori informazioni.</p>
                                              <p>
                                              <ContattiBtnModal riffarma={farma.rif_farm} titolo= {farma.titolo_inserzione}/>
                                              </p>
                                            </div>
                                          </div>
        
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          : <NonDisponibile /> };
                          <div className="col-12" id='Comprare una farmacia'>
                          </div>
                        </div>
                      </article>
                    </div>
                    < SideBar />
                  </div>
                </div>
              </div>
            </>
          );
    }
}
export default FarmaciaInVenditaCorpo;